.custom-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-item {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0);
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
  color: #434343;
  font-family: "IBM Plex Sans Regular";
  font-size: 0.9rem;
}

[aria-label="Start"] {
  color: #00a5a5;
}

[aria-label="End"] {
  color: #00a5a5;
}

[aria-label="Next"] {
  color: #00a5a5;
  margin-left: 1rem;
}

[aria-label="Prev"] {
  color: #00a5a5;
  margin-right: 1rem;
}

.custom-item--active {
  color: #00a5a5;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.custom-item--disable {
  /*opacity: 0.5;*/
  color: #434343;
}
.custom-root ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-go-item {
  align-items: center;
  display: flex;
  font-size: 1rem;
  padding-inline-end: 0.75rem;
  padding-inline-start: 0.75rem;
  width: 150px;
}
.custom-go-item input {
  /*border: 1px solid rgba(0, 0, 0, 0.1);*/
  /*border-radius: 4px;*/
  height: 40px;
  width: 100%;
}
