/*


 */
@font-face {
  font-family: "IBM Plex Sans Bold";
  src: local("IBM Plex Sans Bold"),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf);
}

@font-face {
  font-family: "IBM Plex Sans Light";
  src: local("IBM Plex Sans Light"),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf);
}

@font-face {
  font-family: "IBM Plex Sans Regular";
  src: local("IBM Plex Sans Regular"),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf);
}

@font-face {
  font-family: "IBM Plex Sans Italic";
  src: local("IBM Plex Sans Italic"),
    url(./fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf);
}

/*


 */

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto Bold"), url(./fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto Light";
  src: local("Roboto Light"), url(./fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto Regular"), url(./fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto Italic";
  src: local("Roboto Italic"), url(./fonts/Roboto/Roboto-Italic.ttf);
}

/*


 */

@font-face {
  font-family: "Space Grotesk";
  src: local("Space Grotesk"),
    url(./fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf);
}

/*


 */

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter/Inter-VariableFont_slntuffwght.ttf);
}

/*


 */

@font-face {
  font-family: "Abel";
  src: local("Abel"), url(./fonts/Abel/Abel-Regular.ttf);
}
