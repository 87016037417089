* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
}

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 520px;
    width: 100%;
    text-align: center;
    line-height: 1.4;
}

.notfound .notfound-404 {
    height: 190px;
}

.notfound .notfound-404 h1 {
    font-family: "IBM Plex Sans Regular", sans-serif;
    font-size: 146px;
    font-weight: 700;
    margin: 0px;
    color: #232323;
}

.notfound .notfound-404 h1>span {
    display: inline-block;
    width: 120px;
    height: 120px;
    background-image: url("src/images/emoji.png");
    background-size: cover;
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
    z-index: -1;
}

.notfound h2 {
    font-family: "IBM Plex Sans Regular", sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    color: #232323;
}

.notfound p {
    font-family: "IBM Plex Sans Regular", sans-serif;
    color: #787878;
    font-weight: 300;
}

.notfound a, .notfound button {
    font-family: "IBM Plex Sans Regular", sans-serif;
    display: inline-block;
    padding: 12px 30px;
    margin-top: 12px;
    font-weight: 700;
    background-color: black;
    color: #fff;
    border-radius: 40px;
    text-decoration: none;
    transition: 0.2s all;
    cursor: pointer;
}

.notfound a:hover, .notfound button:hover {
    opacity: 0.8;
}


@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
        height: 115px;
    }
    .notfound .notfound-404 h1 {
        font-size: 86px;
    }
    .notfound .notfound-404 h1>span {
        width: 86px;
        height: 86px;
    }
}